exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-analisis-js": () => import("./../../../src/pages/Analisis.js" /* webpackChunkName: "component---src-pages-analisis-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../../../src/pages/Aviso-De-Privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-casosde-exito-js": () => import("./../../../src/pages/CasosdeExito.js" /* webpackChunkName: "component---src-pages-casosde-exito-js" */),
  "component---src-pages-cfl-different-js": () => import("./../../../src/pages/CflDifferent.js" /* webpackChunkName: "component---src-pages-cfl-different-js" */),
  "component---src-pages-cfl-values-js": () => import("./../../../src/pages/CFLValues.js" /* webpackChunkName: "component---src-pages-cfl-values-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/Contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-costes-comisiones-js": () => import("./../../../src/pages/Costes-Comisiones.js" /* webpackChunkName: "component---src-pages-costes-comisiones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mantenimiento-js": () => import("./../../../src/pages/Mantenimiento.js" /* webpackChunkName: "component---src-pages-mantenimiento-js" */),
  "component---src-pages-politicas-de-cookies-js": () => import("./../../../src/pages/Politicas-de-cookies.js" /* webpackChunkName: "component---src-pages-politicas-de-cookies-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/Preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-quejas-js": () => import("./../../../src/pages/Quejas.js" /* webpackChunkName: "component---src-pages-quejas-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/Registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-risks-js": () => import("./../../../src/pages/Risks.js" /* webpackChunkName: "component---src-pages-risks-js" */),
  "component---src-pages-solicitantes-tasas-js": () => import("./../../../src/pages/Solicitantes-Tasas.js" /* webpackChunkName: "component---src-pages-solicitantes-tasas-js" */),
  "component---src-pages-terminos-de-uso-js": () => import("./../../../src/pages/Terminos-de-uso.js" /* webpackChunkName: "component---src-pages-terminos-de-uso-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/Terminos-y-Condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-success-stories-js": () => import("./../../../src/templates/successStories.js" /* webpackChunkName: "component---src-templates-success-stories-js" */)
}

